import { noop } from 'lodash'
import Paper from 'paper'
import { distinctUntilChanged, map, takeWhile } from 'rxjs'
import { Coordinate } from '../../../../../../models/activeDrawable'
import { DRAWING_TYPES } from '../../../../../../shared/constants/drawable-types'
import { KeyNames } from '../../../../../../shared/constants/key-names'
import { RootState } from '../../../../../../stores'
import {
    applyScaleFactorToPathArea,
    applyScaleFactorToPathLength,
    convertScaleFactorLabelEnumToDecimal,
} from '../../../../../../utils/calculations/scaleConversion/scaleConversion'
import { initial2DState } from '../../../../../slices/2D'
import { NormalizedDocumentChunk } from '../../../../../slices/documents'
import { initialToolsState } from '../../../../../slices/tools'
import {
    Cursors,
    IMUP2DCoordinatesToUpdate,
    IMUP2DDrawableCutout,
    IMUPState,
    IStateMediator,
    ItemScale,
    ITool,
    MeasurementsToUpdate,
    MouseButtonCodes,
    MouseButtonsCodes,
    PaperToolConfig,
    REGION_ENUMS,
    VIEW_MODE,
} from '../../../../../types'

/**
 *  PaperTool.ts
 *
 *  Interface definition for Paper.js tools
 *  Handles interfacing with state and initial tool setup
 *  More functionality is added in Base tool subclasses depending on tool requirements
 */
export class PaperTool extends Paper.Tool implements ITool {
    protected mediator: IStateMediator
    protected paper: typeof Paper
    protected measureStrokeColor: string = 'red'
    protected measureStrokeWidth: number = 2
    protected defaultCrosshairMarkerSize: number = 100
    protected activeMappingId: IMUPState['mappings']['activeMappingId'] = null
    protected projectDocumentMappings: IMUPState['documents']['projectDocumentMappings'] = null
    protected scaleFactor: IMUPState['2D']['scaleFactor'] = initial2DState.scaleFactor
    protected documentChunks: IMUPState['documents']['documentChunks'] = null
    protected activeFloorDocId: IMUPState['documents']['activeFloorDocId'] = null
    protected activeDocumentChunkId: IMUPState['documents']['activeDocumentChunkId'] = null
    protected selectedItemId: IMUPState['2D']['selectedItem'] = initial2DState.selectedItem
    protected strokeWidth: IMUPState['tools']['strokeWidth'] = initialToolsState.strokeWidth
    protected calibrationRatio: IMUPState['tools']['calibrationRatio'] = initialToolsState.calibrationRatio
    protected snappingEnabled: IMUPState['tools']['snappingEnabled'] = initialToolsState.snappingEnabled
    protected isSnappingTemporaryDisabled: IMUPState['tools']['isSnappingTemporaryDisabled'] =
        initialToolsState.isSnappingTemporaryDisabled
    protected maxImageDimension: IMUPState['2D']['maxDimension'] = initial2DState.maxDimension

    private static DEGREE_INCREMENT = 45
    private static readonly HANDLE_MAX_SIZE_IN_PX = 75

    public currentActiveCursor: string | null = null

    name?: string
    cursor: string = Cursors.AUTO

    constructor(config: PaperToolConfig) {
        super()
        this.paper = config.paper
        this.mediator = config.mediator

        this.mediator
            .get$()
            .pipe(
                takeWhile((state: IMUPState) => state.common.activeMode === VIEW_MODE.Markup2D),
                map((state: IMUPState) => ({
                    measureStrokeColor: state.tools.measureStrokeColor,
                    measureStrokeWidth: state.tools.measureStrokeWidth,
                    crosshairMarkerSize: state.tools.crosshairMarkerSize,
                    scaleFactor: state['2D'].scaleFactor,
                    activeFloorDocId: state.documents.activeFloorDocId,
                    activeDocumentChunkId: state.documents.activeDocumentChunkId,
                    documentChunks: state.documents.documentChunks,
                    selectedItem: state['2D'].selectedItem,
                    strokeWidth: state['tools'].strokeWidth,
                    activeMappingId: state.mappings.activeMappingId,
                    projectDocumentMappings: state.documents.projectDocumentMappings,
                    calibrationRatio: state.tools.calibrationRatio,
                    snappingEnabled: state.tools.snappingEnabled,
                    isSnappingTemporaryDisabled: state.tools.isSnappingTemporaryDisabled,
                    maxDimension: state['2D'].maxDimension,
                    stateCursor: state.common.cursor,
                })),
                distinctUntilChanged()
            )
            .subscribe(
                ({
                    measureStrokeColor,
                    measureStrokeWidth,
                    crosshairMarkerSize,
                    scaleFactor,
                    activeFloorDocId,
                    activeDocumentChunkId,
                    documentChunks,
                    selectedItem,
                    strokeWidth,
                    activeMappingId,
                    projectDocumentMappings,
                    calibrationRatio,
                    snappingEnabled,
                    maxDimension,
                    isSnappingTemporaryDisabled,
                    stateCursor,
                }) => {
                    this.measureStrokeColor = measureStrokeColor
                    this.measureStrokeWidth = measureStrokeWidth
                    this.defaultCrosshairMarkerSize = crosshairMarkerSize
                    this.scaleFactor = scaleFactor
                    this.activeFloorDocId = activeFloorDocId
                    this.activeDocumentChunkId = activeDocumentChunkId
                    this.documentChunks = documentChunks
                    this.selectedItemId = selectedItem
                    this.strokeWidth = strokeWidth
                    this.activeMappingId = activeMappingId
                    this.projectDocumentMappings = projectDocumentMappings
                    this.calibrationRatio = calibrationRatio
                    this.snappingEnabled = snappingEnabled
                    this.maxImageDimension = maxDimension
                    this.isSnappingTemporaryDisabled = isSnappingTemporaryDisabled
                    this.currentActiveCursor = stateCursor
                }
            )
    }

    /**
     * Determines the target radius at a given zoom level
     */
    protected calculateHandleRadiusAtCurrentZoom = (): number => {
        const sizeAtCurZoom = (this.maxImageDimension * ItemScale.HANDLE) / this.paper.view.zoom
        return sizeAtCurZoom < PaperTool.HANDLE_MAX_SIZE_IN_PX ? sizeAtCurZoom : PaperTool.HANDLE_MAX_SIZE_IN_PX
    }

    /**
     * Intended to be used on the `mouseDown` event to determine if the click should be used as a pan click or a normal click
     * @param event
     * @returns boolean -> true if it is a panning click
     */
    protected isPanningClick = (event: paper.ToolEvent): boolean => {
        // indicate pan is active on right click
        const clickCode = event['event']['button']

        if ([MouseButtonCodes.Right, MouseButtonCodes.Middle].includes(clickCode)) {
            this.setState('common', { cursor: Cursors.GRAB })
            return true
        }
        return false
    }

    /**
     * Converts a PathItem to the expected payload for the API
     * @param item
     * @param scaleFactor
     * @returns Obj {
     *       coordinates, -> core coordinates of the item (in the case of a compound path, this is the first item in the CompoundPath segment list)
     *       measurements, -> the appropriate dimension (length or area) of the PathItem scaled from path coordinates to IRL coordinatess
     *       cutouts, -> in the case of a compound path, this is an array of type IMUP2DDrawableCutout otherwise it's undefined
     *    }
     */
    protected convertPaperItemToAPICoordinateModel = (
        item: paper.PathItem,
        scaleFactor: number
    ): IMUP2DCoordinatesToUpdate => {
        let coordinates: Coordinate[] = []
        let cutouts: IMUP2DDrawableCutout[] | undefined = undefined
        let measurements: MeasurementsToUpdate = {
            quantity: null,
            linear_total: null,
            area: null,
        }

        // When item is a paper.Path, we will have either a single list of a single coordinate for a POINT type
        // or we will have a list of coordinates that represent the points in a line
        // For a compound path, we will have a list of the lists of coordinates that represent the entire path with all child segments
        if (item instanceof this.paper.Path) {
            if (item.data.shapeType === DRAWING_TYPES.POINT) {
                coordinates = [[item.position.x, item.position.y]] // since a point will only be one coordinate (segments of a point will return the circle)
            } else {
                coordinates = item.segments.map((segment) => [segment.point.x, segment.point.y])
            }

            if (item.data.shapeType === DRAWING_TYPES.SECTION) {
                measurements.linear_total = applyScaleFactorToPathLength({
                    pxValue: item.length,
                    scaleFactor: this.scaleFactor,
                    dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                    xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                    yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                    coordinates: coordinates,
                    pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                })
                measurements.quantity = measurements.linear_total
            } else if (item.data.shapeType === DRAWING_TYPES.AREA) {
                // For Area materials, quantity is Square & linear_total is Perimeter
                measurements.quantity = applyScaleFactorToPathArea({
                    pxValue: Math.abs(item.area),
                    scaleFactor: this.scaleFactor,
                    dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                    xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                    yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                    pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                })
                measurements.area = measurements.quantity
                measurements.linear_total = applyScaleFactorToPathLength({
                    pxValue: item.length,
                    scaleFactor: this.scaleFactor,
                    dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                    xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                    yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                    coordinates: coordinates,
                    pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                })
            }
        } else if (item instanceof this.paper.CompoundPath) {
            // The outside path will be the primary set of coordinates to update
            const outsidePath = item.children[0] as paper.Path
            coordinates = outsidePath.segments.map((segment) => {
                return [segment.point.x, segment.point.y]
            })

            // All child paths of the CompoundPath will be treated as cutouts
            const cutoutPaths = item.children.slice(1) as paper.Path[]
            const parentPath = item.children[0] as paper.Path
            let cutoutAreaTotal = 0
            cutouts = cutoutPaths.map((path) => {
                cutoutAreaTotal += Math.abs(path.area)
                return {
                    coordinates: path.segments.map((segment) => [segment.point.x, segment.point.y]),
                }
            })

            if (item.data.shapeType === DRAWING_TYPES.SECTION) {
                measurements.linear_total = applyScaleFactorToPathLength({
                    pxValue: item.length,
                    scaleFactor: this.scaleFactor,
                    dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                    xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                    yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                    coordinates: coordinates,
                    pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                })
                measurements.quantity = measurements.linear_total
            } else if (item.data.shapeType === DRAWING_TYPES.AREA) {
                // For Area materials, quantity is Square & linear_total is Perimeter
                measurements.quantity = applyScaleFactorToPathArea({
                    pxValue: Math.abs(parentPath.area) - cutoutAreaTotal,
                    scaleFactor: this.scaleFactor,
                    dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                    xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                    yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                    pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                })
                measurements.area = measurements.quantity
                measurements.linear_total = applyScaleFactorToPathLength({
                    pxValue: item.length,
                    scaleFactor: this.scaleFactor,
                    dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                    xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                    yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                    coordinates: coordinates,
                    pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                })
            }
        }

        return {
            coordinates,
            measurements,
            cutouts,
        }
    }

    /**
     * Intended to be used in the `onMouseDrag` event to perform the pan within a tool
     * @param event
     * @returns boolean -> true if is a panning drag
     */
    protected toolPanning = (event: paper.ToolEvent): boolean => {
        const clickCode = event['event']['buttons'] // these `buttons` codes are different than than the `button` code. 4 = scroll click, 2 = right click. See: https://www.w3schools.com/jsref/event_buttons.asp

        // for panning on right click & middle click drag
        if ([MouseButtonsCodes.Right, MouseButtonsCodes.Middle].includes(clickCode)) {
            this.setState('common', { cursor: Cursors.GRABBING })

            const diffBetweenStartAndCurrentPos = event.downPoint.subtract(event.point)

            const diffReferencedToPaperCenter = diffBetweenStartAndCurrentPos.add(this.paper.view.center)

            this.paper.view.center = diffReferencedToPaperCenter
            return true
        }
        return false
    }

    protected getActiveDocumentChunk = (): NormalizedDocumentChunk | null => {
        if (this.documentChunks) {
            const searchId = this.activeDocumentChunkId ? this.activeDocumentChunkId : this.activeFloorDocId
            const searchResult = this.documentChunks.find((chunk) => chunk.id === searchId)
            return searchResult !== undefined ? searchResult : null
        } else {
            return null
        }
    }

    /**
     * Snap what the user is drawing to the nearest 45 deg to help draw straight lines
     * @param originPoint the point to measure the angle against
     * @param proposedNewPoint the mouse position used to determine the angle and the nearest 45
     * @returns point on the nearest 45
     */
    protected calculateFixedAnglePoint = (originPoint: paper.Point, proposedNewPoint: paper.Point): paper.Point => {
        const xDiff = proposedNewPoint.x - originPoint.x
        const yDiff = proposedNewPoint.y - originPoint.y
        const baseAngle = new this.paper.Point(xDiff, yDiff).angle
        const magnitude = Math.sqrt(Math.pow(xDiff, 2) + Math.pow(yDiff, 2))
        const newPoint = new this.paper.Point({
            length: magnitude,
            angle: Math.round(baseAngle / PaperTool.DEGREE_INCREMENT) * PaperTool.DEGREE_INCREMENT,
        })
        return newPoint.add(originPoint)
    }

    /**
     * Set the current active scale factor according to either the region or document mapping of the input point
     * @param point the sample point in a region, or document mapping if there is no region
     */
    protected setScaleFromPointClick = (point: paper.Point): number | undefined => {
        // Find the region we are drawing in, so we can set the scale if necessary
        const regionPaths = this.paper.project.getItems({
            data: {
                shapeType: REGION_ENUMS.TYPE,
            },
        })

        const thisRegion = regionPaths.find((r) => r.contains(point))

        // set the region id if point is in region
        this.setState('2D', { regionId: thisRegion?.data?.region_id || null })

        // If this point is in a region, set the scale of that region, otherwise take it from the document mapping
        if (thisRegion && thisRegion.data.scale && thisRegion.data.scale !== '') {
            const scaleFactor = convertScaleFactorLabelEnumToDecimal(thisRegion.data.scale)
            this.setState('2D', { scaleFactor })
            return scaleFactor
        }

        if (this.projectDocumentMappings) {
            const activeMapping = this.projectDocumentMappings.find(
                (mapping) => mapping.id === this.activeMappingId || mapping.document_chunk_id === this.activeFloorDocId
            )
            if (activeMapping) {
                const scaleFactor = convertScaleFactorLabelEnumToDecimal(activeMapping.scale_factor)
                this.setState('2D', { scaleFactor })
                return scaleFactor
            }
        }
    }

    protected activateBaseOnKeyUpActions = (keyEvent): void => {
        // activate snapping if snapping was deactivated by hotkey
        if (keyEvent.key === KeyNames.CONTROL && this.isSnappingTemporaryDisabled && !this.snappingEnabled) {
            this.mediator.mediate('tools', { snappingEnabled: true, isSnappingTemporaryDisabled: false })
        }

        // limit to only fire if there is a selected item and creation is enabled
        if (
            ['delete', 'backspace'].includes(keyEvent.key) &&
            this.selectedItemId &&
            // prevents forms from triggering delete event
            keyEvent.event.target?.tagName.toLowerCase() !== 'input' &&
            keyEvent.event.target?.tagName.toLowerCase() !== 'textarea'
        ) {
            this.mediator.mediate('tools', { deletionModalVisible: true })
        }

        if (keyEvent.key === 'escape') {
            // If we hit the escape key, deactivate the tool
            this.cancel()
        }
    }

    activate = () => {
        super.activate()
        this.mediator.mediate('tools', { activeTool: this.name })
        this.onActivate()
    }

    onActivate = () => {}

    /**
     * onDeactivate()
     * Cancels the current action of the tool and sets the activeTool state to nothing
     */
    onDeactivate = () => {
        this.cancel()
        this.mediator.mediate('tools', { activeTool: '' })
    }

    /**
     * cancel()
     * Cancels the current action of the tool (i.e. remove the shape being drawn or cleanup non drawable paper items)
     * Does NOT deactivate the tool in state
     * Should be implemented in the specific tools when necessary -- no cancellation to be done in Base tool.
     */
    cancel = () => noop()

    /**
     * handle delete
     * @param keyEvent
     */
    onKeyUp = (keyEvent) => {
        this.activateBaseOnKeyUpActions(keyEvent)
    }

    onKeyDown = (keyEvent) => {
        // deactivate snapping when snapping is enabled and hotkey is pressed
        if (keyEvent.key === KeyNames.CONTROL && this.snappingEnabled) {
            this.mediator.mediate('tools', { snappingEnabled: false, isSnappingTemporaryDisabled: true })
        }
    }

    setState = <K extends keyof RootState['IMUP'], V extends Partial<RootState['IMUP'][K]>>(key: K, value: V): this => {
        this.mediator.mediate(key, value)

        return this
    }

    getPaperScope = (): paper.PaperScope => this.paper

    /**
     * Generates a group that marks the point being measured
     * @param point Center point for the marker
     */
    constructCrosshairMarker = (point: paper.Point, length?: number, color?: paper.Color): paper.Group => {
        let elements: Array<paper.Path> = []
        const size = length ?? this.defaultCrosshairMarkerSize
        elements.push(
            new this.paper.Path.Line(
                new this.paper.Point(point.x - size, point.y),
                new this.paper.Point(point.x + size, point.y)
            )
        )
        elements.push(
            new this.paper.Path.Line(
                new this.paper.Point(point.x, point.y - size),
                new this.paper.Point(point.x, point.y + size)
            )
        )

        // apply styling to each line
        elements.forEach((line) => {
            line.style.strokeColor = color ? color : new this.paper.Color(0, 1, 0)
            line.strokeWidth = this.measureStrokeWidth
            line.strokeScaling = false
            line.locked = true
        })
        return new this.paper.Group(elements)
    }

    /**
     * Applies a rounded effect to corners of a paper path in place
     * @param path The path to round
     * @param radius The radius to apply to each vertex
     * @returns The rounded path item (will not be a new instance of a paper.Path)
     */
    public roundPath = (path: paper.Path, radius: number) => {
        path.data.originalCoordinates = path.segments.map((s) => [s.point.x, s.point.y])

        var segments = path.segments.slice(0)
        path.removeSegments()

        /**
         * Credit for rounding logic to:
         * https://stackoverflow.com/questions/25936566/paper-js-achieving-smoother-edges-with-closed-paths
         */
        for (var i = 0, l = segments.length; i < l; i++) {
            var curPoint = segments[i].point
            var nextPoint = segments[i + 1 === l ? 0 : i + 1].point
            var prevPoint = segments[i - 1 < 0 ? segments.length - 1 : i - 1].point
            var nextDelta = curPoint.subtract(nextPoint)
            var prevDelta = curPoint.subtract(prevPoint)

            nextDelta.length = radius
            prevDelta.length = radius

            path.add(new this.paper.Segment(curPoint.subtract(prevDelta), undefined, prevDelta.divide(2)))

            path.add(new this.paper.Segment(curPoint.subtract(nextDelta), nextDelta.divide(2), undefined))
        }

        path.closed = true
        return path
    }

    /**
     * Undoes a rounded effect generated by roundPath to corners of a paper path in place
     * @param path The path to de-round
     * @returns The de-rounded path item (will not be a new instance of a paper.Path)
     */
    public deroundPath = (path: paper.Path) => {
        path.removeSegments()

        for (const coord of path.data.originalCoordinates ?? []) {
            path.add(new this.paper.Point(coord[0], coord[1]))
        }

        path.closed = true
        return path
    }
}

export default PaperTool
