import { createAction } from '@reduxjs/toolkit'
import isArray from 'lodash/isArray'
import isNull from 'lodash/isNull'
import { call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'
import { selectAzureFeatureFlags, setAzureFeatureFlags } from '../../../slices/flags'
import { getAzureFeatureFlags } from '../../../../api/permissions-api'
import { setLoading } from '../../../slices/common'
import { AzureFeatureFlag } from '../../../../models/azureFeatureFlags'

export const fetchAllProjectSettingsDataAction = createAction('fetchAllProjectSettingsData')

export function* handleFetchAllProjectSettingsData() {
    const azureFeatureFlagsExisting: AzureFeatureFlag[] | null = yield select(selectAzureFeatureFlags)

    if (!isNull(azureFeatureFlagsExisting)) return

    yield put(setLoading(true))

    const azureFeatureFlags = yield call(getAzureFeatureFlags)

    if (isArray(azureFeatureFlags) && !!azureFeatureFlags.length) {
        yield put(setAzureFeatureFlags(azureFeatureFlags))
    }

    yield put(setLoading(false))
}

export function* watchForFetchAllProjectSettingsData(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(fetchAllProjectSettingsDataAction.type, handleFetchAllProjectSettingsData)
}
